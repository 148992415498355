import Axios from '../../config/api';
import { 
    GET_PACKAGES_REQUEST ,
    GET_PACKAGES_SUCCESS ,
    GET_PACKAGES_FAIL ,
}
from '../constants/packageConstants';

export const fetchAllPackages = (setPackages) => async dispatch => {
    dispatch({ type : GET_PACKAGES_REQUEST });
    try {
        const user=JSON.parse (localStorage.getItem('user'));

        const { data : { data : { packages } } } = await Axios.get('/admin/package', { 
            headers : {
                'content-type' : 'application/json',
                'Authorization' : `Bearer ${user.token}`

            }
        });
        dispatch({ type : GET_PACKAGES_SUCCESS , payload : packages });
        setPackages(packages);
    } catch (error) {
        dispatch({ type : GET_PACKAGES_FAIL , payload : error?.response?.data?.message || 'something went wrong.'})
    }
};