import axios from 'axios';
import Axios from '../config/api';

export const deleteUser = async (userId) => {
    const user=JSON.parse (localStorage.getItem('user'));

    const { data : { data : { message } } } = await Axios.delete(`/admin/delete-user/${userId}`,{headers : {
        'content-type' : 'application/json',
        'Authorization' : `Bearer ${user.token}`

    }})
    return message;
}