import Axios from '../../config/api';
import {
    GET_CHATS_REQUEST ,
    GET_CHATS_SUCCESS ,
    GET_CHATS_FAIL ,
}
from '../constants/chatConstants';

export const fetchMyChats = (keyword=null, setMyChats=null) => async dispatch => {
    dispatch({ type : GET_CHATS_REQUEST });
    try {
        const user=JSON.parse (localStorage.getItem('user'));

        const { data : { data : { chats } } } = await Axios.get(keyword ? `/chat?keyword=${keyword}` : '/chat',{
            headers : {
                'content-type' : 'application/json',
                'Authorization' : `Bearer ${user.token}`

            }
        });
        dispatch({ type : GET_CHATS_SUCCESS , payload : chats });
        setMyChats(chats);
    } catch (err) {
        dispatch({ type : GET_CHATS_FAIL , payload : err?.response?.data?.message || 'something went wrong.' })
    }
}