import Axios from '../../config/api';
import {
    GET_MESSAGES_REQUEST ,
    GET_MESSAGES_SUCCESS ,
    GET_MESSAGES_FAIL ,
}
from '../constants/messageConstants';
// import axios from 'axios';

export const fetchChatMessages = (chatId, setChatMessages) => async dispatch => {
    dispatch({ type : GET_MESSAGES_REQUEST });
    try {
        const user=JSON.parse (localStorage.getItem('user'));

        const { data : { data : { messages } } } = await Axios.get(`/message/${chatId}`,{headers : {
            'content-type' : 'application/json',
            'Authorization' : `Bearer ${user.token}`

        }});
        dispatch({ type : GET_MESSAGES_SUCCESS , payload : messages });
        setChatMessages(messages);
    } catch (err) {
        dispatch({ type : GET_MESSAGES_FAIL , payload : err?.response?.data?.message || 'something went wrong.' })
    }
}