import axios from 'axios';
import Axios from '../config/api';

export const createChat = async (userId,isAdminChat=false) => {
    const user=JSON.parse (localStorage.getItem('user'));

    const { data : { data : { chat } } } = await Axios.post('/chat' , { 
        userId , isAdminChat 
    } , {
        headers : {
            'content-type' : 'application/json',
            'Authorization' : `Bearer ${user.token}`

        }
    });
    return chat ; 
}

export const deleteChat = async (chatId) => {
    const { data : { data : { message} } } = await Axios.delete(`/chat/${chatId}`);
    return message;
}