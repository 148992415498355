import Axios from '../../config/api';
import {
    GET_PRODUCTS_REQUEST ,
    GET_PRODUCTS_SUCCESS ,
    GET_PRODUCTS_FAIL 
}
from '../constants/productConstants';

export const createProduct  = async ( productData , setLoading , toast , setProducts , setShowAddItemPopup , setImage ) =>  {
    try {
        const user=JSON.parse (localStorage.getItem('user'));

        setLoading(true);
        const { data : { data : { product } } } = await Axios.post('/product' , productData , {
            headers : {
                'content-type' : 'application/json',
                'Authorization' : `Bearer ${user.token}`

            }
        });
        setLoading(false);
        setShowAddItemPopup(false);
        setProducts(prev => [product , ...prev]);
        setImage('');
        toast.success('Product created successfully.');
    } catch (err) {
        setLoading(false);
        console.log('create product error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'something went wrong.')
    }
}


export const getProducts = () => async dispatch => {
    console.log('calling')
    const user=JSON.parse (localStorage.getItem('user'));

    try {
        dispatch({ type : GET_PRODUCTS_REQUEST });
        const { data : { data : { products , pages } } } = await Axios.get('/product' , { 
            headers : {
                'content-type' : 'application/json',
                'Authorization' : `Bearer ${user.token}`

            }
        });
        dispatch({ type : GET_PRODUCTS_SUCCESS , payload : { products , pages } });
    } catch (err) {
        dispatch({ type : GET_PRODUCTS_FAIL , payload : err.response && err.response.data.message ? err.response.data.message : err.message || 'something went wrong.'})
    }
}

export const fetchProducts = (page = 1 , type = '' , keyword = '' , setAllProducts ) => async dispatch =>  {
    try {
        const user=JSON.parse (localStorage.getItem('user'));

        dispatch({ type : GET_PRODUCTS_REQUEST });
        const { data : { data : { products , pages , currentPage } } } = await Axios.get(`${
            type && keyword ? `/product?pageNumber=${page}&type=${type}&keyword=${keyword}` 
            : type ? `/product?pageNumber=${page}&type=${type}` 
            : keyword ? `/product?pageNumber=${page}&keyword=${keyword}` : `/product?pageNumber=${page}` 
            } ` , { 
                headers : {
                    'content-type' : 'application/json',
                    'Authorization' : `Bearer ${user.token}`
    
                }
        });
        dispatch({ type : GET_PRODUCTS_SUCCESS , payload : { products , pages , currentPage } });
        setAllProducts(products);
    } catch (err) {
        dispatch({ type : GET_PRODUCTS_FAIL , payload : err?.response?.data?.message || 'something went wrong.'})
    }
}