import axios from "axios";

// export const baseURL = 'http://localhost:5000';
export const baseURL = 'https://precision-645b31ae91ff.herokuapp.com';


const Axios = axios.create({
    baseURL : `${baseURL}/api` ,
});


export const useApi = () => {
  
    Axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                // dispatch(logout(navigate , false)); 
                // localStorage.setItem('user' , null)
            }
            return Promise.reject(error);
        }
    );
    return Axios;
};

export default Axios;